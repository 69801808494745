import React, { useState, useEffect } from 'react';
import './App.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { FormControl, Typography } from '@mui/material';
// import axios from 'axios';
import Services from './services/Services';
import FileUpload from './components/FileUpload';
import logo from './img/logo.png'

function App() {

  const [selectedType, setSelectedType] = useState("");
  const [topic, setTopic] = useState("");
  const [username, setUsername] = useState("");
  const [details, setDetails] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [token, setToken] = useState("");

  const supportTypes =
    [
      { value: "MallLogisticsNet", title: "MallLogisticsNet" },
      { value: "Mall Logistics App", title: "Mall Logistics App" },
      { value: "Handsfree Store Panel", title: "Handsfree Mağaza Panel" },
      { value: "Handsfree Point Panel", title: "Handsfree Nokta Panel" },
      { value: "General", title: "Genel Talep" },
      { value: "Porter App", title: "Porter App" },
      { value: "Technical Support", title: "Teknik Destek" },
      { value: "Development", title: "Geliştirme Talebi" },
      { value: "Report Bug", title: "Hata Bildirimi" },
    ]

  const allFieldsAreValid = () => {
    if (selectedType !== '' && topic !== '' && username !== '' && details !== '') {
      return true
    }
    else
      return false
  };

  useEffect(() => {
    Services.GetToken().then((response) => {
      if (response.data.status) {
        setToken(response.data.result.token)
      }
      else {
        console.log("Hata meydana geldi");
      }
    })

  }, []);

  const handleCloseMessage = () => {
    setShowResultMessage(false)
    setSelectedType("")
    setTopic("")
    setUsername("")
    setDetails("")
    setSelectedFiles([])
  }

  const handleUpload = () => {
    setIsBackdropOpen(true)
    let fileArr = []
    for (let i = 0; i < selectedFiles.length; i++) {
      fileArr.push(selectedFiles[i].base64.base64)
    }
    Services.CheckUser(token, username).then((response) => {
      if (response.data.status) {
        Services.CreateTicket(token, topic, details, selectedType, fileArr, username)
          .then((response) => {
            setIsBackdropOpen(false)
            setShowResultMessage(true)
            setResultMessage("Talebiniz başarıyla iletildi.")
          })
          .catch((error) => {
            setIsBackdropOpen(false)
            setShowResultMessage(true)
            setResultMessage("Talebiniz iletilirken bir sorunla karşılaşıldı. Lütfen daha sonra tekrar deneyin.")
          })
      }
      else {
        setIsBackdropOpen(false)
        setShowResultMessage(true)
        setResultMessage("Destek talebi açmaya yetkiniz bulunmamaktadır. Lütfen talebinizi info@easypoint.com.tr adresine e-posta olarak iletiniz.")
      }
    })

  }


  return (
    <div className="App">

      <Backdrop
        sx={{ color: '#fff', zIndex: 2 }}
        open={isBackdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={showResultMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Destek Talebi"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>
            Tamam
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ width: '100%', height: '64px', backgroundColor: '#172955', alignItems: 'flex-start', justifyContent: 'flex-start', display: 'flex', top: 0, marginBottom: '1%', boxShadow: '2px 4px 5.5px 0px #0000000D', alignItems: 'center' }}>
        <img src={logo} alt="ML Logo" style={{ width: '131px', marginLeft: '8%', marginTop: '5px' }} />
      </Box>

      <Typography sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '600', color: '#DD4640', letterSpacing: '-0.2%', '@media screen and (max-width: 600px)': { fontSize: '12px' } }}>
        BİZE ULAŞIN
      </Typography>
      <Typography sx={{ fontFamily: 'Inter', fontSize: '26px', fontWeight: '600', color: '#475467', letterSpacing: '-0.2%', marginBottom: '20px', '@media screen and (max-width: 600px)': { fontSize: '22px' } }}>
        DESTEK TALEBİ OLUŞTUR
      </Typography>

      <Card sx={{
        width: '41.77%', justifyContent: 'center', display: 'flex', marginBottom: '15px', paddingTop: '13px', '@media screen and (max-width: 600px)': { width: '90%' }
      }}>
        <CardContent sx={{ width: '83.33%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <Box
            sx={{
              width: '100%',
              '@media screen and (max-height: 670px)': {
                marginBottom: '100px'
              }
            }}
          >
            <Typography sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: '600', lineHeight: '24px', marginBottom: '10px', display: { xs: 'none', sm: 'block' } }}>
              Talep Formu
            </Typography>
            <Typography sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '400', lineHeight: '24px', color: '#667085', marginBottom: '15px', display: { xs: 'none', sm: 'block' } }}>
              Lütfen destek almak istediğiniz konu ve destek birimine yönelik olarak gerekli alanları doldurarak talebinizi bize iletiniz.
            </Typography>
          </Box>


          <FormControl fullWidth margin="normal">
            <InputLabel>Destek birimi seçiniz</InputLabel>
            <Select
              sx={{ width: '100%' }}
              label="Destek birimi seçiniz"
              disabled={supportTypes.length === 0}
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              name="supportType"
            >
              {supportTypes?.map((type, index) => (
                <MenuItem key={index} value={type.value}>{type.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="topic"
            label="Konu*"
            variant="outlined"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            sx={{ mb: 1, mt: 1 }}
            fullWidth />
          <TextField
            id="username"
            label="Email / Kullanıcı Adı*"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ mb: 1, mt: 1 }}
            fullWidth />
          <TextField
            id="details"
            label="İletmek istediğiniz mesajı yazın..."
            variant="outlined"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            sx={{ mb: 2, mt: 1 }}
            fullWidth
            multiline
            rows={5}
          />

          <FileUpload setFiles={setSelectedFiles} files={selectedFiles} />
          <Button lang='tr' disabled={!allFieldsAreValid()} onClick={() => handleUpload()} sx={{
            marginTop: '37px', backgroundColor: '#DD4640', width: '180x', height: '44px', '&:hover': {
              backgroundColor: '#AA3933'
            }
          }} variant="contained">Talebi Gönder</Button>
        </CardContent>
      </Card>
    </div>
  );
}

export default App;
