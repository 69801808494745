import axios from "axios";
import { BASE_URL } from ".";

const url = BASE_URL

export default
    {
        CreateTicket(token, title, description, type, files, username) {
            return axios({
                method: 'post',
                url: `${url}/support/create-ticket`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    title: title,
                    description: description,
                    files: files,
                    type: type,
                    username: username
                },
            });
        },

        GetToken() {
            return axios({
                method: 'post',
                url: `${url}/get-token`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    key: "wHjOKqH25WwOEeHphyGR",
                    secret: "37m2LWciLhboNNFA6UkQ"
                },
            });
        },

        GetTicketTypes(token) {
            return axios({
                method: 'post',
                url: `${url}/support/get-ticket-types`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
        },

        CheckUser(token, username) {
            return axios({
                method: 'post',
                url: `${url}/flow/users-check-by-username`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    username: username,
                },
            });
        }
    }